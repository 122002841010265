import React from 'react';

import { Components, Theme } from '@mui/material';
import Link, { LinkProps } from 'next/link';

const MuiLink: Components<Theme>['MuiLink'] = {
  defaultProps: {
    underline: 'none',
    component: React.forwardRef(
      (props: LinkProps, ref: React.ForwardedRef<HTMLAnchorElement>) =>
        React.createElement(Link, { ref, ...props }),
    ),
  },
  styleOverrides: {
    root: ({ theme }) => ({
      textDecoration: 'none',
      position: 'relative',

      '&:after': {
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: '-0.3em',
        borderBottom: '1px solid currentcolor',
        opacity: 0.5,
        transition: theme.transitions.create('opacity'),
        pointerEvents: 'none',
        display: 'none',
      },

      '&:hover': {
        ':after': {
          opacity: 1,
        },
      },
    }),
    underlineNone: {
      '&:after': {
        display: 'none',
      },
    },
    underlineAlways: {
      '&:after': {
        display: 'block',
      },
    },
    underlineHover: {
      '&:hover': {
        textDecoration: 'none',
      },
      '&:after': {
        display: 'block',
        opacity: 0,
      },
    },
  },
};

export default MuiLink;
