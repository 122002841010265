import React from 'react';

import { Components, Theme, SvgIcon } from '@mui/material';

import ChevronDown from '@/public/icons/chevron-down.svg';

const MuiSelect: Components<Theme>['MuiSelect'] = {
  defaultProps: {
    IconComponent: (props) =>
      React.createElement(SvgIcon, props, React.createElement(ChevronDown)),
  },
  styleOverrides: {
    select: ({ theme, ownerState }) => ({
      minHeight: 26,
      lineHeight: '26px',
      color:
        !ownerState.value && ownerState.displayEmpty
          ? theme.palette.grey['400']
          : undefined,
    }),
    icon: ({ theme }) => ({ color: theme.palette.text.primary }),
  },
};

export default MuiSelect;
