import React, { useEffect, useState } from 'react';

import { Alert, AlertColor, Zoom } from '@mui/material';

import classes from './index.module.scss';

interface ItemProps {
  content?: React.ReactNode;
  color?: AlertColor;
  onClose: () => void;
}

const Item: React.FC<ItemProps> = (props) => {
  const { color, content, onClose } = props;

  const [isVisible, setIsVisible] = useState(false);
  const [closing, setClosing] = useState(false);

  const autoDeleteTime = 7500;

  const timeout = 300;

  const handleRemove = () => {
    if (!closing) {
      setClosing(true);
      setIsVisible(false);
      setTimeout(() => {
        onClose();
      }, timeout);
    }
  };

  useEffect(() => {
    setIsVisible(true);

    setTimeout(() => {
      handleRemove();
    }, autoDeleteTime);
  }, []);

  return (
    <Zoom in={isVisible}>
      <div className={classes.item}>
        <Alert onClose={onClose} severity={color} sx={{ width: '100%' }}>
          {content}
        </Alert>
      </div>
    </Zoom>
  );
};

export default Item;
