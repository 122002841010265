'use client';

import React from 'react';

import useNotifications from '@/app/_store/notifications';

import Item from './item';

import classes from './index.module.scss';

const Notification: React.FC = () => {
  const { list, removeNotification } = useNotifications();

  return (
    <div className={classes.wrap}>
      {list.map((notification) => (
        <div className={classes.item} key={notification.id}>
          <Item
            onClose={() => removeNotification(notification.id)}
            color={notification.type}
            content={notification.content}
          />
        </div>
      ))}
    </div>
  );
};

export default Notification;
