import { Components, Theme, alpha, sliderClasses } from '@mui/material';

const MuiSlider: Components<Theme>['MuiSlider'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      height: 1,
      padding: '10px 0',
      '@media (pointer: coarse)': {
        padding: '10px 0',
      },
    },
    thumb: ({ ownerState, theme }) => ({
      width: 13,
      height: 13,

      [`&.${sliderClasses.focusVisible}`]: {
        boxShadow: `0 0 0 8px ${alpha(theme.palette[ownerState.color || 'primary'].main, 0.2)}`,
      },

      [`:hover:not(.${sliderClasses.focusVisible})`]: {
        boxShadow: `0 0 0 6px ${alpha(theme.palette[ownerState.color || 'primary'].main, 0.2)}`,
      },

      '&:after': {
        width: 27,
        height: 27,
      },
    }),
    mark: {
      height: '13px',
      width: '1px',
      transform: 'translate(-50%, 100%)',
      top: 'auto',
      bottom: '50%',
    },
    markLabel: ({ theme }) => ({
      [theme.breakpoints.down('md')]: {
        fontSize: 10,
      },
    }),
    valueLabel: ({ theme }) => ({
      backgroundColor: 'transparent',
      fontWeight: 400,
      '&:before': {
        display: 'none',
      },
      transform: 'translateY(-100%, -50%)',
      [theme.breakpoints.down('md')]: {
        top: 0,
      },
    }),
  },
};

export default MuiSlider;
