'use client';

import React, { useState } from 'react';

import { Collapse, Theme, useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import Link from 'next/link';

import ChevronDown from '@/public/icons/chevron-down.svg';
import { NormalizedMenuItem } from '@/types/menus';

import classes from './index.module.scss';

type ItemType = Omit<NormalizedMenuItem, 'name'> & {
  name: string | JSX.Element;
};

interface MenuProps {
  title: string;
  items: ItemType[];
}

const Menu: React.FC<MenuProps> = (props) => {
  const { title, items } = props;
  const [isOpen, setIsOpen] = useState(false);

  const isMdDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  return (
    <div>
      <button
        type="button"
        className={classes.title}
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
        <div
          className={classNames(classes.iconWrap, { [classes.open]: isOpen })}
        >
          <ChevronDown />
        </div>
      </button>
      <Collapse in={!isMdDown || isOpen}>
        <div>
          {items.map((item) => (
            <div key={item.id} className={classes.navItem}>
              {item.href ? (
                <Link href={item.href}>{item.name}</Link>
              ) : (
                item.name
              )}
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
};

export default Menu;
