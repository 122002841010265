import { createTheme } from '@mui/material/styles';

import components from './components';
import baseTheme from './theme';

const theme = createTheme(baseTheme, {
  components,
});

export default theme;
