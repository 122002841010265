import React from 'react';

import { Box } from '@mui/material';
import Link from 'next/link';

import ArrowIcon from '@/public/icons/chevron-right.svg';

import classes from './main-nav.module.scss';

type Props = {
  name: string | JSX.Element;
  href: string;
  onClick?: () => void;
  hasGroups: boolean;
  classNames?: string;
};

const LinkItem: React.FC<Props> = (props) => {
  const { name, href, hasGroups, onClick, classNames } = props;

  return (
    <Box
      component={href ? Link : 'span'}
      href={href || undefined}
      target={href.startsWith('/') ? '_self' : '_blank'}
      className={classes.box}
      onClick={onClick}
      prefetch={href ? false : undefined}
    >
      <div className={classNames}>
        {name}
        {hasGroups ? <ArrowIcon className={classes.arrow} /> : null}
      </div>
    </Box>
  );
};

export default LinkItem;
