import React from 'react';

import { Components, Theme } from '@mui/material';

import StarFilled from '@/public/icons/star-filled.svg';
import Star from '@/public/icons/star.svg';

const MuiRating: Components<Theme>['MuiRating'] = {
  defaultProps: {
    icon: React.createElement(StarFilled),
    emptyIcon: React.createElement(Star),
  },
  styleOverrides: {
    sizeLarge: { fontSize: 39 },
    sizeExtraLarge: {
      fontSize: 88,
    },
    root: ({ theme }) => ({
      color: theme.palette.grey['500'],
      fontSize: 26,
    }),
  },
};

export default MuiRating;
