import MuiAccordion from './accordion';
import MuiAccordionDetails from './accordion-details';
import MuiAccordionSummary from './accordion-summery';
import MuiButton from './button';
import MuiButtonBase from './button-base';
import MuiChip from './chip';
import MuiContainer from './container';
import MuiFormHelperText from './form-helper-text';
import MuiIconButton from './icon-button';
import MuiInputBase from './input-base';
import MuiLink from './link';
import MuiMenuItem from './menu-item';
import MuiOutlinedInput from './outlined-input';
import MuiRating from './rating';
import MuiSelect from './select';
import MuiSlider from './slider';
import MuiTablePagination from './table-pagination';

const components = {
  MuiAccordion,
  MuiAccordionSummary,
  MuiAccordionDetails,
  MuiButton,
  MuiButtonBase,
  MuiChip,
  MuiContainer,
  MuiFormHelperText,
  MuiIconButton,
  MuiInputBase,
  MuiLink,
  MuiMenuItem,
  MuiOutlinedInput,
  MuiRating,
  MuiSelect,
  MuiSlider,
  MuiTablePagination,
};

export default components;
