import { IntegrationData, SquarespaceData } from '@/types/integrations';

import api, { Response } from './api';

export const getSquarespace = () =>
  api<Response<SquarespaceData>>({
    url: '/integrations/squarespace',
  });

export const getIntegration = ({
  integration,
}: {
  integration: 'typeform' | 'google_analytics';
}) =>
  api<Response<IntegrationData[]>>({
    url: '/integrations',
    params: {
      integration,
    },
  });
