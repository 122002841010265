import { Components, Theme } from '@mui/material';

const MuiChip: Components<Theme>['MuiChip'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      borderRadius: 0,
    },
  },
};

export default MuiChip;
