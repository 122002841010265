import { Components, Theme, outlinedInputClasses } from '@mui/material';

const MuiOutlinedInput: Components<Theme>['MuiOutlinedInput'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      [`&:not(.${outlinedInputClasses.error})`]: {
        '&:hover': {
          [`.${outlinedInputClasses.notchedOutline}`]: {
            borderColor: theme.palette.divider,
          },
        },
        [`&.${outlinedInputClasses.focused}`]: {
          [`.${outlinedInputClasses.notchedOutline}`]: {
            borderColor: theme.palette.divider,
          },
        },
        [`&.${outlinedInputClasses.disabled}`]: {
          [`.${outlinedInputClasses.notchedOutline}`]: {
            borderColor: theme.palette.divider,
          },
        },
      },
      [`&.${outlinedInputClasses.error}`]: {
        [`&.${outlinedInputClasses.disabled}`]: {
          [`.${outlinedInputClasses.notchedOutline}`]: {
            borderColor: theme.palette.error.main,
          },
        },
      },
      [`&.${outlinedInputClasses.focused}`]: {
        [`.${outlinedInputClasses.notchedOutline}`]: {
          borderWidth: 1,
        },
      },
    }),

    input: {
      padding: '10px 20px',
      height: 26,
    },

    notchedOutline: ({ theme }) => ({
      borderColor: theme.palette.divider,
    }),

    sizeSmall: {
      [`.${outlinedInputClasses.input}`]: {
        padding: '7px 20px',
      },
    },
  },
};

export default MuiOutlinedInput;
