import { Components, Theme, accordionClasses } from '@mui/material';

const MuiAccordion: Components<Theme>['MuiAccordion'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      boxShadow: 'none',
      [`&.${accordionClasses.expanded}`]: {
        margin: 0,
        '&:before': {
          opacity: 1,
        },
      },
    },
  },
};

export default MuiAccordion;
