import * as yup from 'yup';

yup.setLocale({
  string: {
    email: 'Email must be in valid format',
  },
});

const { formatError } = yup.ValidationError;

yup.ValidationError.formatError = (message, params) =>
  formatError.call(this, message, {
    ...params,
    label: params.label || 'This field',
  });
