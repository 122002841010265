import { SimplePaletteColorOptions } from '@mui/material';

const common = {
  background: '#ffffff',
  text: '#000000',
};

export const palette: Record<string, SimplePaletteColorOptions> = {
  primary: {
    light: '#5E566B',
    main: '#50495B',
    contrastText: common.background,
  },

  secondary: {
    main: '#E8E5EE',
    light: '#F3F0F8',
    dark: '#d6d0e2',
    contrastText: '#50495B',
  },

  // not specialized design, can be changed
  error: {
    main: '#E57373',
    dark: '#D32F2F',
    light: '#FFCDD2',
    contrastText: '#fff',
  },

  // not specialized design, can be changed
  success: {
    main: '#81C784',
    dark: '#388E3C',
    light: '#C8E6C9',
    contrastText: '#50495B',
  },

  bright: {
    main: common.background,
    contrastText: common.text,
  },

  accent: {
    main: common.text,
    contrastText: common.background,
    dark: '#322e37',
  },
};

const colors = {
  text: {
    primary: common.text,
    secondary: '#8B8B8B',
  },

  common: {
    white: common.background,
  },

  background: {
    paper: common.background,
    default: common.background,
    secondary: '#EBEAE6',
  },
  ...palette,

  grey: {
    '50': '#E6E6E6',
    '100': '#e8eaee',
    '200': '#E9E5EE',
    '300': '#DDD8E7',
    '400': '#A4A198',
    '500': '#9185A3',
    '600': '#8B8A84',
    '700': '#72706B',
    '800': '#5A5954',
    '900': '#42423E',
  },

  divider: '#E1E1E1',
};

export default colors;
