import { useEffect, useRef } from 'react';

const useBodyScrollLock = () => {
  const scrollLockCountRef = useRef(0);

  useEffect(() => {
    const { body } = document;
    if (scrollLockCountRef.current > 0) {
      body.classList.add('no-scroll');
    } else {
      body.classList.remove('no-scroll');
    }
  }, [scrollLockCountRef.current]);

  const lockScroll = () => {
    scrollLockCountRef.current += 1;
    if (scrollLockCountRef.current === 1) {
      document.body.classList.add('no-scroll');
    }
  };

  const unlockScroll = () => {
    if (scrollLockCountRef.current > 0) {
      scrollLockCountRef.current -= 1;
      if (scrollLockCountRef.current === 0) {
        document.body.classList.remove('no-scroll');
      }
    }
  };

  return { lockScroll, unlockScroll };
};

export default useBodyScrollLock;
