'use client';

import { CacheProvider } from '@emotion/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';

import createCache from '@emotion/cache';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { useServerInsertedHTML } from 'next/navigation';

import theme from '@/libs/mui';
import '@/libs/yup';

interface ThemeProviderProps {
  children?: React.ReactNode;
}

const queryClient = new QueryClient();

const Providers: React.FC<ThemeProviderProps> = (props) => {
  const { children } = props;

  const [{ cache, flush }] = React.useState(() => {
    const localCache = createCache({ key: 'mui' });
    localCache.compat = true;
    const prevInsert = localCache.insert;
    let inserted: string[] = [];
    localCache.insert = (...args) => {
      const serialized = args[1];
      if (localCache.inserted[serialized.name] === undefined) {
        inserted.push(serialized.name);
      }
      return prevInsert(...args);
    };
    const localFlush = () => {
      const prevInserted = inserted;
      inserted = [];
      return prevInserted;
    };
    return { cache: localCache, flush: localFlush };
  });

  useServerInsertedHTML(() => {
    const names = flush();
    if (names.length === 0) {
      return null;
    }
    let styles = '';
    names.forEach((name) => {
      styles += cache.inserted[name];
    });

    return (
      <style
        key={cache.key}
        data-emotion={`${cache.key} ${names.join(' ')}`}
        dangerouslySetInnerHTML={{
          __html: styles,
        }}
      />
    );
  });

  return (
    <QueryClientProvider client={queryClient}>
      <CacheProvider value={cache}>
        <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
      </CacheProvider>
    </QueryClientProvider>
  );
};

export default Providers;
