import { Components, menuItemClasses, Theme } from '@mui/material';

const MuiMenuItem: Components<Theme>['MuiMenuItem'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      [`&.${menuItemClasses.disabled}`]: {
        opacity: 1,
        color: theme.palette.grey['400'],
      },
    }),
  },
};

export default MuiMenuItem;
