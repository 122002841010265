import { createTheme } from '@mui/material';

import breakpoints from './breakpoints';
import palette from './palette';
import typography from './typography';

const theme = createTheme({
  palette,
  typography,
  breakpoints,
  spacing: 10,
  shape: {
    borderRadius: 0,
  },
});

export default theme;
