import { PaletteOptions } from '@mui/material/styles/createPalette';

import colors from '@/config/theme/colors';

const pallet: PaletteOptions = {
  mode: 'light',
  ...colors,
};

export default pallet;
