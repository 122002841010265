'use client';

import React from 'react';
import SimpleBar, { Props } from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import classes from './index.module.scss';

const ScrollBar: React.FC<
  Omit<Props, 'children'> & {
    children?: React.ReactNode;
    maxHeight?: number | string;
  }
> = (props) => {
  const { maxHeight, children, ...rest } = props;
  return (
    <SimpleBar
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      style={{ maxHeight: maxHeight || '100%', width: '100%' }}
      className={classes.scrollbar}
    >
      <div>{children}</div>
    </SimpleBar>
  );
};
export default ScrollBar;
