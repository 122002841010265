'use client';

import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';

import Script from 'next/script';

import { googleAnalyticsKeyQueryCreator } from '@/store/integrations';

const GoogleAnalytics: FC = () => {
  const { data: googleAnalytics } = useQuery(googleAnalyticsKeyQueryCreator());

  const googleAnalyticsKey =
    googleAnalytics &&
    googleAnalytics.length &&
    googleAnalytics[0] &&
    googleAnalytics[0].value;

  if (!googleAnalyticsKey) return null;

  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsKey}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${googleAnalyticsKey}');
          `}
      </Script>
    </>
  );
};

export default GoogleAnalytics;
