import { Components, Theme } from '@mui/material';

const MuiIconButton: Components<Theme>['MuiIconButton'] = {
  defaultProps: { color: 'primary' },
  styleOverrides: {
    root: {
      borderRadius: 0,
    },
  },
};

export default MuiIconButton;
