'use client';

import React from 'react';

import { AppProgressBar as ProgressBar } from 'next-nprogress-bar';

const Progress = () => (
  <ProgressBar
    height="4px"
    color="#9185a3"
    options={{ showSpinner: false }}
    shallowRouting
  />
);

export default Progress;
