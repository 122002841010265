'use client';

import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';

import { Collapse, IconButton } from '@mui/material';

import Image from '@/app/_components/image';
import XIcon from '@/public/icons/x.svg';
import { settingsQueryCreator } from '@/store/general';

import EmailForm from '../_components/email-form';

import classes from './discount.module.scss';

const popupDelayTime = 10;
const onSuccessDelayTime = 3;

interface Props {}

const DiscountPopup: React.FC<Props> = () => {
  const [open, setOpen] = useState(false);

  const { data: settings } = useQuery(settingsQueryCreator());

  const getEndOfDayTimestamp = (): number =>
    new Date().setHours(23, 59, 59, 999);

  const handleClose = () => {
    setOpen(false);
    window.localStorage.setItem(
      'end-of-day-timestamp',
      JSON.stringify(getEndOfDayTimestamp()),
    );
  };

  const onSuccess = () => {
    setTimeout(() => {
      handleClose();
    }, onSuccessDelayTime * 1000);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const endOfDayTimestamp = Number(
        window.localStorage.getItem('end-of-day-timestamp') || '1',
      );
      const currentTimestamp = Date.now();

      setTimeout(() => {
        setOpen(currentTimestamp > endOfDayTimestamp);
      }, popupDelayTime * 1000);
    }
  }, []);

  if (
    !settings ||
    (!settings?.promoTitle &&
      !settings?.promoSubTitle &&
      !settings?.promoDescription &&
      !settings?.promoIcon &&
      !settings?.promoButton)
  ) {
    return null;
  }

  return (
    <div className={classes.wrap}>
      <Collapse in={open} orientation="horizontal" unmountOnExit mountOnEnter>
        <div className={classes.contentWrap}>
          <div className={classes.closeBtnWrap}>
            <IconButton onClick={handleClose}>
              <XIcon />
            </IconButton>
          </div>
          <div className={classes.wrapper}>
            <div className={classes.img}>
              <Image src={settings?.promoIcon || ''} alt="" fill />
            </div>
            <div className={classes.textBlock}>
              <div className={classes.title}>{settings?.promoTitle}</div>
              <div className={classes.subtitle}>{settings?.promoSubTitle}</div>
              <div className={classes.text}>{settings?.promoDescription}</div>
              <div className={classes.explanation}>
                Enter your Email and receive your promo code.
              </div>
              <div className={classes.formWrapper}>
                <EmailForm
                  btnText={settings?.promoButton || ''}
                  onSuccess={onSuccess}
                />
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default DiscountPopup;
