import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@mui/material';
import * as yup from 'yup';

import { subscribe } from '@/api/subscription';

import classes from './email-form.module.scss';

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

type Fields = yup.InferType<typeof schema>;

interface Props {
  btnText: string;
  onSuccess: () => void;
}

const EmailForm: React.FC<Props> = (props) => {
  const { btnText, onSuccess } = props;
  const [sending, setSending] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<Fields>({ resolver: yupResolver(schema) });

  const fields = {
    email: register('email'),
  };

  const onSubmit = async (data: Fields) => {
    setSending(true);
    try {
      await subscribe(data.email);

      setIsSuccess(true);
      onSuccess();
    } catch (e) {
      setError('email', { message: (e as { message: string }).message });
    }
    setSending(false);
  };

  if (isSuccess) {
    return (
      <div className={classes.success}>
        Thank you! You have successfully subscribed
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.wrap}>
        <TextField
          fullWidth
          color="accent"
          ref={fields.email.ref}
          name={fields.email.name}
          onChange={fields.email.onChange}
          onBlur={fields.email.onBlur}
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
          placeholder="Your Email"
          classes={{
            root: classes.inputRoot,
          }}
        />
        <div className={classes.btnWrap}>
          <Button
            type="submit"
            variant="contained"
            disabled={sending}
            classes={{
              root: classes.btnRoot,
            }}
          >
            {btnText}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default EmailForm;
