'use client';

import { useEffect, useState } from 'react';

const useHeaderClientRect = () => {
  const header =
    typeof document !== 'undefined' ? document.getElementById('header') : null;

  const [clientRect, setClientRect] = useState(
    header?.getBoundingClientRect() || {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: 0,
      height: 0,
    },
  );

  const setTopPosition = () => {
    if (header) {
      setClientRect(header.getBoundingClientRect());
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', setTopPosition);

    return () => window.removeEventListener('scroll', setTopPosition);
  }, []);

  return clientRect;
};

export default useHeaderClientRect;
