'use client';

import React, { useState } from 'react';

import { useMediaQuery, useTheme } from '@mui/material';

import { NormalizedMenuGroup } from '@/types/menus';

import LinkItem from './link-item';
import SubMenu from './sub-menu';

import classes from './main-nav.module.scss';

type Props = {
  name: string | JSX.Element;
  href?: string | null;
  onClick?: () => void;
  image?: string | null;
  groups?: NormalizedMenuGroup[] | null;
  imgTitle?: string | null;
  imgDescription?: string | null;
  imgDestination?: string | null;
};

const MenuItem: React.FC<Props> = (props) => {
  const {
    name,
    href,
    image,
    groups,
    onClick,
    imgTitle,
    imgDescription,
    imgDestination,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [open, setOpen] = useState(false);

  return (
    <>
      <LinkItem
        name={name}
        href={isMobile && groups ? '' : href || ''}
        onClick={() => {
          if (onClick) onClick();
          if (isMobile) {
            setOpen(true);
          }
        }}
        hasGroups={Boolean(groups?.length)}
        classNames={classes.parentLink}
      />
      {groups?.length ? (
        <SubMenu
          name={name}
          open={open}
          onClose={() => setOpen(false)}
          itemsList={groups}
          imgUrl={image || undefined}
          imgTitle={imgTitle || ''}
          imgDescription={imgDescription || ''}
          imgDestination={imgDestination || ''}
        />
      ) : null}
    </>
  );
};

export default MenuItem;
