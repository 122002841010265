import React from 'react';

import { Components, Theme, accordionSummaryClasses } from '@mui/material';

import ChevronDown from '@/public/icons/chevron-down.svg';

const MuiAccordionSummary: Components<Theme>['MuiAccordionSummary'] = {
  defaultProps: {
    expandIcon: React.createElement(ChevronDown),
  },
  styleOverrides: {
    root: {
      textTransform: 'uppercase',
      fontWeight: 500,
      lineHeight: '18px',
      padding: 0,
    },
    content: {
      margin: '27px 0',
      [`&.${accordionSummaryClasses.expanded}`]: {
        margin: '27px 0 18px 0',
      },
    },
    expandIconWrapper: {
      width: 20,
      height: 20,
      svg: {
        width: '100%',
        height: '100%',
        minWidth: 20,
      },
    },
  },
};

export default MuiAccordionSummary;
