import { Components, Theme } from '@mui/material';

const MuiContainer: Components<Theme>['MuiContainer'] = {
  defaultProps: { maxWidth: false },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      [theme.breakpoints.up('xxl')]: {
        paddingLeft: ownerState.maxWidth === false ? 90 : 30,
        paddingRight: ownerState.maxWidth === false ? 90 : 30,
      },
      [theme.breakpoints.down('xxl')]: {
        paddingLeft: 30,
        paddingRight: 30,
      },
    }),
  },
};

export default MuiContainer;
