import { Components, Theme } from '@mui/material';

const MuiAccordionDetails: Components<Theme>['MuiAccordionDetails'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      padding: '0 0 25px',
    },
  },
};

export default MuiAccordionDetails;
