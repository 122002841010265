import { Components, Theme } from '@mui/material';

const MuiFormHelperText: Components<Theme>['MuiFormHelperText'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      margin: '3px 0 0',
    },
  },
};

export default MuiFormHelperText;
