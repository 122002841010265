'use client';

import React, { useState } from 'react';

import { Badge, IconButton } from '@mui/material';
import classNames from 'classnames';
import dynamic from 'next/dynamic';

import { COMPARISON, WISHLIST } from '@/config/routes';
import useDiamondCompareList from '@/hooks/use-diamond-compare-list';
import useShopingCart from '@/hooks/use-shoping-cart';
import useWishlist from '@/hooks/use-wishlist';
import BagIcon from '@/public/icons/bag.svg';
import DiamondIcon from '@/public/icons/diamond.svg';
import HeartIcon from '@/public/icons/heart.svg';
import SearchIcon from '@/public/icons/search.svg';

import classes from '../index.module.scss';

const Search = dynamic(() => import('../../search'), { ssr: false });

const MenuIcons = () => {
  const { fullList, setOpen, open } = useShopingCart();
  const { diamondsList, variantsList } = useWishlist();
  const comparisonCount = useDiamondCompareList().data.length;
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  return (
    <div>
      <div className={classes.menuIcons}>
        <IconButton
          className={classNames(classes.btn, {
            [classes.active]: isSearchOpen,
          })}
          onClick={() => setIsSearchOpen(!isSearchOpen)}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
        <IconButton href={WISHLIST.fullPath} className={classes.btn}>
          <Badge
            badgeContent={diamondsList.length + variantsList.length}
            color="primary"
          >
            <HeartIcon />
          </Badge>
        </IconButton>
        <IconButton href={COMPARISON.fullPath} className={classes.btn}>
          <Badge badgeContent={comparisonCount} color="primary">
            <DiamondIcon />
          </Badge>
        </IconButton>
        <IconButton
          className={classes.btn}
          onClick={() => setOpen(!open)}
          aria-label="Shopping Cart"
        >
          <Badge badgeContent={fullList.length} color="primary">
            <BagIcon />
          </Badge>
        </IconButton>
        <Search open={isSearchOpen} onClose={() => setIsSearchOpen(false)} />
      </div>
    </div>
  );
};

export default MenuIcons;
