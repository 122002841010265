'use client';

import TawkMessenger from '@tawk.to/tawk-messenger-react';
import React from 'react';

const Tawk: React.FC = () =>
  process.env.NEXT_PUBLIC_TAWK_PROPERTY_ID &&
  process.env.NEXT_PUBLIC_TAWK_WIDGET_ID ? (
    <TawkMessenger
      propertyId={process.env.NEXT_PUBLIC_TAWK_PROPERTY_ID || ''}
      widgetId={process.env.NEXT_PUBLIC_TAWK_WIDGET_ID || ''}
    />
  ) : null;

export default Tawk;
