'use client';

import React, { useEffect, useState } from 'react';

import { IconButton } from '@mui/material';
import classNames from 'classnames';
import { usePathname } from 'next/navigation';

import { normalizeMenu } from '@/helpers/menus';
import { MenuItem as MainItemType } from '@/types/menus';

import MenuItem from './menu-item';

import classes from './main-nav.module.scss';

type Props = {
  mainMenu: MainItemType[];
};

const MainMenu: React.FC<Props> = (props) => {
  const { mainMenu } = props;
  const [open, setOpen] = useState(false);

  const normalizedMenu = normalizeMenu(mainMenu, () => setOpen(false));

  const pathname = usePathname();

  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  return (
    <div
      className={classNames(classes.mainMenuWrap, {
        [classes.open]: open,
      })}
      key={pathname}
    >
      <IconButton
        className={classes.callMenuBtn}
        onClick={() => {
          setOpen(!open);
        }}
        aria-label="menu"
      >
        <span className={classes.line} />
        <span className={classes.line} />
        <span className={classes.line} />
      </IconButton>
      <nav className={classes.mainMenu}>
        {normalizedMenu.map((item) => (
          <div key={item.name} className={classes.menuItem}>
            <MenuItem
              name={item.name}
              href={item.href}
              image={item.subMenu?.image}
              groups={item.subMenu?.groups}
              imgTitle={item.subMenu?.imgTitle}
              imgDescription={item.subMenu?.imgDescription}
              imgDestination={item.subMenu?.imgDestination}
            />
          </div>
        ))}
      </nav>
    </div>
  );
};

export default MainMenu;
