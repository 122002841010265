import { Components, Theme } from '@mui/material';

const MuiInputBase: Components<Theme>['MuiInputBase'] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      fontSize: 16,
      lineHeight: 1.25,
    },

    input: ({ theme }) => ({
      '&::placeholder': {
        opacity: 1,
        color: theme.palette.grey['400'],
      },
    }),
  },
};

export default MuiInputBase;
