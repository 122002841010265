import { DIAMONDS, JEWELRY } from '@/config/routes';
import { MenuItem, NormalizedMenuItem } from '@/types/menus';

import { splitSelfDomain } from './url';

export const getMenuItemHref = (menuItem: {
  url: string | null;
  category?: {
    slug: string;
    id: number;
    parent?: { slug: string | null; id: number } | null;
  } | null;
  shape?: { id: number } | null;
  style?: { id: number } | null;
}) => {
  if (menuItem.category) {
    if (menuItem.category.parent) {
      return `${JEWELRY.fullPath}/${menuItem.category.parent.slug}?sub-category=${menuItem.category.id}`;
    }
    return `${JEWELRY.fullPath}/${menuItem.category.slug}`;
  }
  if (menuItem.shape) {
    return `${DIAMONDS.fullPath}?shape=${menuItem.shape.id}`;
  }
  if (menuItem.style) {
    return `${JEWELRY.fullPath}?styleId=${menuItem.style.id}`;
  }
  return menuItem.url && splitSelfDomain(menuItem.url);
};

export const normalizeMenuItem = (
  menuItem: MenuItem,
  onClick?: () => void,
): NormalizedMenuItem => ({
  id: menuItem.id,
  href: getMenuItemHref(menuItem),
  name: menuItem.title,
  priority: menuItem.priority,
  imageUrl: menuItem.imageUrl,
  group: menuItem.group,
  onClick,
});

export const normalizeMenu = (
  menu: MenuItem[],
  onItemClick?: () => void,
): NormalizedMenuItem[] =>
  menu
    .map((item) => {
      const normalizedItem = normalizeMenuItem(item, onItemClick);

      const { subItems, root, groups } = item;

      if (root && subItems) {
        const itemGroups = [...(groups || []), ...(subItems ? [null] : [])].map(
          (groupName) => ({
            title: groupName,
            children: normalizeMenu(subItems, onItemClick).filter(
              (sItem) => sItem.group === groupName,
            ),
          }),
        );

        return {
          ...normalizedItem,
          subMenu: {
            image: item.imageUrl,
            groups: itemGroups,
            imgTitle: item.imgTitle,
            imgDescription: item.imgDescription,
            imgDestination: splitSelfDomain(item.imgDestination || ''),
          },
        };
      }

      return normalizedItem;
    })
    .sort((a, b) => (a.priority || 0) - (b.priority || 0));

export const normalizedFooterMenu = (menu: MenuItem[]) =>
  menu
    .map((el) => ({
      ...el,
      subItems: (el.subItems || [])
        .map((item) => normalizeMenuItem(item))
        .sort((a, b) => (a.priority || 0) - (b.priority || 0)),
    }))
    .sort((a, b) => (a.priority || 0) - (b.priority || 0));
