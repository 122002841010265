const breakpoints = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1190,
  xl: 1536,
  xxl: 1800,
};

export default breakpoints;
