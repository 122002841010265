import { Components, Theme } from '@mui/material';

const MuiTablePagination: Components<Theme>['MuiTablePagination'] = {
  defaultProps: {
    slotProps: { select: { variant: 'outlined', size: 'small' } },
  },
  styleOverrides: {
    select: {
      paddingLeft: 20,
    },
    selectLabel: {
      textTransform: 'uppercase',
    },

    displayedRows: {
      marginRight: 20,
      textTransform: 'uppercase',
    },

    selectRoot: {
      marginLeft: 20,
      marginRight: 40,
    },
  },
};

export default MuiTablePagination;
