import { getIntegration, getSquarespace } from '@/api/integrations';

const keys = {
  squarespace: 'squarespace/list',
  typeform: 'typeform/list',
  googleAnalyticsKey: ' google-analytics',
};

export const squarespaceQueryCreator = () => ({
  queryKey: [keys.squarespace],
  queryFn: async () => {
    const { data } = await getSquarespace();
    return data;
  },
});

export const typeformQueryCreator = () => ({
  queryKey: [keys.typeform],
  queryFn: async () => {
    const { data } = await getIntegration({
      integration: 'typeform',
    });
    return data;
  },
});

export const googleAnalyticsKeyQueryCreator = () => ({
  queryKey: [keys.googleAnalyticsKey],
  queryFn: async () => {
    const { data } = await getIntegration({
      integration: 'google_analytics',
    });
    return data;
  },
});
