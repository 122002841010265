import React from 'react';

import { Components, Theme } from '@mui/material';
import Link, { LinkProps } from 'next/link';

const MuiButton: Components<Theme>['MuiButton'] = {
  defaultProps: {
    variant: 'outlined',
    LinkComponent: React.forwardRef(
      (props: LinkProps, ref: React.ForwardedRef<HTMLAnchorElement>) =>
        React.createElement(Link, { ref, ...props }),
    ),
  },
  styleOverrides: {
    root: {
      fontSize: '1rem',
      lineHeight: 1.2,
      padding: '10px 20px',
      textAlign: 'center',
    },

    contained: {
      boxShadow: 'none',

      '&:hover': {
        boxShadow: 'none',
      },
    },

    outlined: {
      // root padding - border width
      padding: '9px 19px',
    },

    sizeLarge: {
      fontSize: 24,
      fontWeight: 500,
    },
  },
};

export default MuiButton;
